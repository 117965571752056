import React from 'react';
import { useDispatch } from 'react-redux'
import Layout from "../../../components/NonUkResidents/HCP/layout";
import SEO from "../../../components/seo"
import styles from "../../../components/innerPage.module.css"
import { graphql,  Link, navigate } from 'gatsby';
import { deriveHomePathFromSpace, isNullUndefined , addDisclaimersToCmsLinks, richTextOptions, capitalizeFirstLetter} from "../../../util"
import ScrollAnimation from 'react-animate-on-scroll';
import {showNonUkResidentsHcpDisclaimerPopup} from "../../../actions/nonUkResidents/hcp/index"
import { toggleNonUkResidentsHcpDisclaimerPopup } from '../../../controllers/nonUkResidents/hcp/disclaimer'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer" 
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from "react-html-parser"
import { getDefaultNonUkResidentsHcpDisclaimerPopupConfigs, config } from '../../../util/configs'
import { Markup } from 'interweave';
import queryString from "query-string"
import TemplateWrapper from "../../../components/TemplateWrapper"
import Auth from '@aws-amplify/auth';
import videoPoster from "../../../images/italianVideoPoster.png"

export const pageQueryFurtherInformationNonUkHcp = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulFurtherInformationTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
                slug
                spaceId
                title
                bannerImage {
                    file {
                        url
                    }
                }
                description {
                    json
                }
                resources {
                    internal {
                        content
                    }
                }
        }
    }
`

const FurtherInformationPageNonUkHcp = (props) => {

    const dispatch = useDispatch()

    const {slug, title, spaceId, description, resources, bannerImage} = props.data.contentfulFurtherInformationTemplate;

    let richText = documentToReactComponents(isNullUndefined(description) ? description : description.json, richTextOptions)

    let bodyHtml = ReactDOMServer.renderToStaticMarkup(richText)

    const showExternalLinkDisclaimer = (externalUrl) => {
        let okCallback = () => window.open (externalUrl, "_blank")
        let closeCallback = () => {}
        let actionConfig = getDefaultNonUkResidentsHcpDisclaimerPopupConfigs(okCallback, closeCallback)
        dispatch(showNonUkResidentsHcpDisclaimerPopup(actionConfig));
    }

    let [veevaDisclaimer, setVeevaDisclaimer] = React.useState(false);
    let [revealPage, setRevealPage] = React.useState(false);

    let resourcesJson = null;
    let resourcesInternalContent = resources?.internal?.content;

    if(resourcesInternalContent){
        try {
            resourcesJson = JSON.parse(resourcesInternalContent);
        }catch(e){}
    }

    React.useEffect(() => {
        let {veevaDisclaimer} = queryString.parse(props.location.search || "");
  
        if(veevaDisclaimer){
          setVeevaDisclaimer(true);
          showExternalLinkDisclaimer(config.defaultBaseUrl)
        }

        (async () => {
            let isLoggedIn = false;

            try {
                isLoggedIn = await Auth.currentAuthenticatedUser()
            }catch(e){}

            if(!isLoggedIn){
                navigate("/NonUkResidents/HCP/information-for-italian-prescribers/?username=%20");
                return;
            }

            setRevealPage(true);
        })()
  
    }, []);

    if(!revealPage){
        return <React.Fragment />;
    }

    return ( 
        <TemplateWrapper>
            <Layout>
            <SEO title={`${capitalizeFirstLetter(title)} | Non-UK Residents | HCP`} />

            <div className={styles.banner}  style={{background:`url(${isNullUndefined(bannerImage) ? "":bannerImage.file.url})`}}>
                <div className={styles.overlay}></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                            
                            <h1 className="">
                            {title}
                            </h1>
                            <ol className="custom_breadcrumb">
                            <li className="breadcrumb-item"><Link to={'/NonUkResidents/HCP/home'}>Home</Link></li>
                                
                        
                                <li className="breadcrumb-item active" aria-current="page">
                                    {title}
                                </li>
                            </ol>
                        
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className="">
            <div className="container">

                <div className="">
                    <div className={styles.video_content}>
                        {ReactHtmlParser(bodyHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, showExternalLinkDisclaimer)})}
                    </div>
                </div>
                </div>

                        {resourcesJson && Object.entries(resourcesJson)?.map(([sectionTitle, resourcesList], index) => {
                            return (
                                <div className="" key={index}>
                                    <div className={styles.video_content} > 
                                    
                                        <div className="container">
                                        <h4>{capitalizeFirstLetter(`${sectionTitle}`)}</h4>
                                        </div>
                                        {resourcesList?.map((resource, ind) => {
                                            if(resource.type === "pdf"){
                                                return (
                                                    <div className="container">
                                                    <div className="row" key={ind}>
                                                        <div className="col-12 col-md-12">
                                                            <div className={styles.video_content}>
                                                                <img src="https://images.ctfassets.net/bm339k2zg0t6/3x0fn4Lmu2q2ma2WCqEq4z/5a575dce55c3ddca41ed365d431fc1e3/pdf_icon-82033e2ae055d4bef4788e96a29a5d49.png"/>
                                                                <p><a href={resource.fileUrl} target="_blank">{resource.title}</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                )
                                            }

                                            if(resource.type === "video"){
                                                return (
                                                    <div style={{backgroundColor:"#e2e2e2", padding:"40px", marginBottom:"30px"}}>
                                                        <div className="container">
                                                    <div className="row" key={ind}>
                                                        <div className="col-12 col-md-8">
                                                            <div className={styles.video_content}>
                                                            <video width="100%" controls poster={videoPoster}>
                                                                    <source src={resource.fileUrl}  type="video/mp4"/>
                                                                    </video>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-4">
                                                            <p>
                                                            {resource.description}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                                )
                                            }

                                            return<React.Fragment /> 
                                        })}
                                    </div>
                                </div>
                            )
                        })}

                   </div>

            </Layout>
        </TemplateWrapper>
     );
}
 
export default FurtherInformationPageNonUkHcp;